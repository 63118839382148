<template>
    <div>
        <ModalConfirmation ref="confirm"></ModalConfirmation>
        <div>
            <v-row>
                <v-col>
                    <v-container grid-list-xs>
                        <v-skeleton-loader type="card" :loading="isLoading">
                            <v-row>
                                <v-col class="text-center">
                                    <v-simple-table>
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th v-for="(item, index) in headers" :key="index" class="text-left">
                                                        {{ item.text }}
                                                    </th>
                                                    <th>Opciones</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, index) in limnimeterShow" :key="index">
                                                    <td v-for="(field, index) in headers" :key="`${index}-tbody`"
                                                        class="text-left">
                                                        <template>
                                                            {{ item[field.value] }}
                                                        </template>
                                                    </td>
                                                    <td class="d-flex " style="gap:22px; align-items: center;">
                                                        <div @click="editLimnimiter(item)">
                                                            <span class="mdi mdi-file-edit-outline button-options"
                                                                style="font-size:24px"></span>
                                                        </div>
                                                        <div @click="removeLimnimeter(item)">
                                                            <span class="mdi mdi-delete button-options"
                                                                style="font-size:24px"></span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-col>
                            </v-row>
                        </v-skeleton-loader>
                    </v-container>
                </v-col>
            </v-row>
        </div>
        <div class="d-flex justify-center mt-6">
            <LimnimeterListPagination :currentPage="currentPage" :totalPages="totalPages"
                @update:currentPage="currentPage = $event" />
        </div>
        <Alert ref="success"></Alert>
    </div>
</template>


<script>

import { mapState, mapActions } from 'vuex';
import LimnimeterListPagination from './LimnimeterListPagination.vue';
import ModalConfirmation from '@/components/ModalConfirmation.vue';
import Alert from '@/components/Alert.vue';


export default {
    name: 'limnimeter-list',
    data() {
        return {
            headers: [
                { text: 'Nombre', value: 'name' },
                { text: 'Estado', value: 'stateShow' },
                { text: 'Fecha de Instalación', value: 'installation_date' }
            ],
            currentPage: 1,
            itemsPerPage: 10,
            page: 1,
        };
    },
    props: {
        limnimetersList: {
            type: Array,
            required: true
        },
        changeState: {
            type: Function,
            required: true
        }
    },
    components: {
        LimnimeterListPagination,
        ModalConfirmation,
        Alert
    },
    computed: {
        ...mapState({
            limnimeters: state => state.limnimetersControl.limnimeters,
            isLoading: state => state.limnimetersControl.isLoading,
        }),
        limnimeterShow() {
            const start = (this.currentPage - 1) * this.itemsPerPage;
            const end = start + this.itemsPerPage;
            let limnimetersFiltered = this.$utils.deepCopy(this.limnimetersList).map((limnimeter) => {
                limnimeter.stateShow = limnimeter.state ? 'Activo' : 'Inactivo'
                return limnimeter
            })
            // return limnimetersFiltered
            return limnimetersFiltered.slice(start, end)
        },
        totalPages() {
            let limnimeterList = this.$utils.deepCopy(this.limnimetersList).map((limnimeter) => {
                limnimeter.stateShow = limnimeter.state ? 'Activo' : 'Inactivo'
                return limnimeter
            })
            return Math.ceil(limnimeterList.length / this.itemsPerPage);
        },
        paginatedData() {
            const start = (this.currentPage - 1) * this.itemsPerPage;
            const end = start + this.itemsPerPage;
            return this.items.slice(start, end);
        },
    },
    methods: {
        ...mapActions('limnimetersControl', ['getLimnimeter', 'deleteLimnimeter', 'loadLimnimeters']),
        editLimnimiter(item) {
            this.getLimnimeter(item)
            this.changeState('edit')
        },
        async removeLimnimeter(item) {
            if (await this.$refs.confirm.open('Confirmar', '¿Seguro que desea eliminar?', { color: '#cc1515' })) {
                this.deleteLimnimeter(item.id).finally(() => {
                    this.loadLimnimeters()
                    this.$refs.success.open('Limnímetro eliminado correctamente', 'success')
                })
            }
            else {
                console.log("Operación cancelada")
            }
        }
    }

};
</script>

<style>
.button-options {
    cursor: pointer
}
</style>