<template>
    <div>
        <v-row>
            <v-col>
                <a>Limnimetros</a>
            </v-col>
        </v-row>
        <v-row class="pt-3">
            <v-col cols="5 d-flex" style="gap:12px;">
                <h2 class="text-h4">
                    Lista de Limnímetros
                </h2>
                <v-btn class="rounded-circle" width="36" height="36" min-width="37" color="info"
                    @click="changeState('create')" v-show="state"><v-icon>mdi-plus</v-icon></v-btn>
            </v-col>
            <v-col cols="7">
                <LimnimeterListFilter :filters="filters" :limnimeters_state_options="options_state" />
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <LimnimeterAdd v-if="state.create" :changeState="changeState" :showNotification="showNotification" />
                <LimnimeterEdit v-if="state.edit" :changeState="changeState" :showNotification="showNotification" />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <LimnimeterList :limnimetersList="limnimetersFiltered" v-if="state.list" :changeState="changeState" />
            </v-col>
        </v-row>
        <Alert ref="success"></Alert>

    </div>
</template>

<script>

import LimnimeterList from '@/components/LimnimeterList';
import LimnimeterListFilter from '../../components/LimnimeterListFilter.vue';
import LimnimeterAdd from '../../components/LimnimeterAdd.vue';
import LimnimeterEdit from '../../components/LimnimeterEdit.vue';
import Alert from '@/components/Alert.vue';

import { mapState, mapActions } from 'vuex';

export default {
    components: {
        LimnimeterList,
        LimnimeterListFilter,
        LimnimeterAdd,
        LimnimeterEdit,
        Alert
    },
    data() {
        return {
            filters: {
                name: '',
                state: null,
                code: ''
            },

            options_state: [
                {
                    code: null,
                    name: 'Todos',
                },
                {
                    code: 'true',
                    name: 'Activo',
                },
                {
                    code: 'false',
                    name: 'Desactivado',
                },
            ],
            state: {
                list: true,
                create: false,
                edit: false
            }
        }
    },
    computed: {
        ...mapState({
            limnimeters: state => state.limnimetersControl.limnimeters,
        }),
        limnimetersFiltered() {
            let limnimetersFiltered = this.$utils.deepCopy(this.limnimeters).filter((limnimeter) => {
                const matchesName = limnimeter.name.toLowerCase().includes(this.filters.name.toLowerCase())
                const matchesState = this.filters.state === null || this.filters.state === '' || String(limnimeter.state) === this.filters.state;
                return matchesName && matchesState
            })
            return limnimetersFiltered
        }
    },
    methods: {
        ...mapActions('limnimetersControl', ['loadLimnimeters']),
        changeState(value) {
            for (const prop in this.state) {
                this.state[prop] = false
            }
            this.state[value] = true
        },
        showNotification(msg) {
            this.$refs.success.open(msg, 'success')
        }
    },
    async mounted() {
        await this.loadLimnimeters()
    },
}
</script>
