<template>


    <div class="d-flex justify-center">

        <div class="pa-3 white" style="min-width: 85%;">
            <v-row class="align-center pa-5 primary" style="color: white;">
                <v-col>
                    <h5 class="text-h5">
                        Crea un Limnímetro nuevo
                    </h5>
                </v-col>

                <v-col class="d-flex justify-end ">
                    <v-btn class="rounded-circle" min-width="36" width="36" height="36" @click="changeState('list')">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-form class="pa-8" ref="form">

                <v-skeleton-loader type="card" :loading="isLoading">

                    <v-container>
                        <v-row class="mb-4">
                            <v-col cols="12" md="6">
                                <h3>Seleccionar Canal</h3>
                                <v-select v-model="formLimnimeter.channels" label="Canal" :items="channelsList"
                                    :rules="[v => !!v.name || 'Seleccione un Canal']" item-text="name" return-object>
                                </v-select>
                            </v-col>
                            <v-col cols="12" md="6">
                                <h3>Fecha de Instalación</h3>
                                <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :nudge-right="40"
                                    transition="scale-transition" offset-y min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="formLimnimeter.installation_date" label="Ingrese Fecha"
                                            :rules="[v => !!v || 'Seleccione una Fecha']" prepend-icon="mdi-calendar"
                                            readonly v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="formLimnimeter.installation_date"
                                        @input="menu = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-row class="mb-4">
                            <v-col cols="12" md="6">
                                <h3>Observacion</h3>
                                <v-textarea v-model="formLimnimeter.observation" clearable label="Ingrese observacion"
                                    aria-label="Campo de observación"></v-textarea>
                            </v-col>
                            <v-col cols="12" md="6">
                                <h3>Estado</h3>
                                <v-switch v-model="formLimnimeter.state" label="Habilitado"></v-switch>
                            </v-col>
                        </v-row>
                        <v-row class="mb-4">
                            <v-col cols="12" md="6">
                                <h3>Curva</h3>
                                <v-text-field v-model="formLimnimeter.curve.min" label="Valor Mínimo"
                                    :rules="[v => v > 0 || 'El Valor debe ser mayor a 0']" type="number"></v-text-field>
                                <v-text-field v-model="formLimnimeter.curve.max" label="Valor Máximo"
                                    :rules="[v => v > 0 || 'El Valor debe ser mayor a 0']" type="number"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <h3>Fórmula</h3>
                                <v-text-field v-model="formLimnimeter.formula.coefficient" label="Coeficiente"
                                    type="number" :rules="[v => v > 0 || 'El coeficiente debe ser mayor a 0']"
                                    step="0.1"></v-text-field>
                                <v-text-field v-model="formLimnimeter.formula.exponent" label="Exponente" type="number"
                                    :rules="[v => v > 0 || 'El Exponente debe ser mayor a 0']"
                                    step="0.1"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-btn block variant="tonal" color="primary" class="pa-4" type="button"
                            @click="sendLimnimeter()">
                            Guardar
                        </v-btn>

                    </v-container>
                </v-skeleton-loader>
            </v-form>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    data() {
        return {
            menu: false,
            fechaInstalacion: null,
            formLimnimeter: {
                channels: {
                    name: '',
                    code: ''
                },
                coordinates: null,
                installation_date: '',
                state: false,
                observation: '',
                formula: {
                    coefficient: 0,
                    exponent: 0
                },
                curve: {
                    min: 1,
                    max: 50
                }
            }
        };
    },
    computed: {
        ...mapState({
            channels: state => state.limnimetersControl.channels,
            isLoading: state => state.limnimetersControl.isLoading,
        }),
        channelsList() {
            let channelsList = this.channels.map(({ name, code }) => ({ name, code }))
            return channelsList
        }
    },
    props: {
        changeState: {
            type: Function,
            required: true
        },
        showNotification: {
            type: Function
        }
    },

    methods: {
        ...mapActions('limnimetersControl', ['loadLimnimeters', 'loadChannels', 'saveLimnimeter']),
        sendLimnimeter() {
            if (this.$refs.form.validate()) {
                let range = []
                for (let i = this.formLimnimeter.curve.min; i <= this.formLimnimeter.curve.max; i++) {
                    range.push({
                        medicion: i
                    })
                }
                let toSendLimnimeter = {
                    ...this.formLimnimeter,
                    curve: range,
                    name: this.formLimnimeter.channels.name
                }
                this.saveLimnimeter(toSendLimnimeter).finally(() => {
                    this.loadLimnimeters()
                    this.showNotification('Limnímetro creado con éxito')
                    this.changeState('list')
                })
            }
            return
        },
    },
    async mounted() {
        await this.loadChannels()
    }
}

</script>
